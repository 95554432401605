<template>
  <v-alert
    color="primary"
    class="mb-0"
  >
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" :class="$vuetify.breakpoint.mdAndUp ? 'pa-8' : ''">
      <v-row :class="$vuetify.breakpoint.mdAndUp ? 'px-5' : ''">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" :class="$vuetify.breakpoint.mdAndUp ? 'mr-5' : ''">
          <h2 :class="$vuetify.breakpoint.mdAndUp ? 'med-text white--text mb-3 px-8' : 'white--text mb-3'">
            Get back on the right path today.
          </h2>
          <p :class="$vuetify.breakpoint.mdAndUp ? 'white--text medtwo-text px-8' : 'white--text'">First time clients are offered a free 15 minute phone consultation with Doug Feil. This initial session will act as a meet and greet to ensure that this is a good fit for you.</p>
            <v-btn large :class="$vuetify.breakpoint.mdAndUp ? 'pa-5 ml-8' : 'pa-5 ml-4 my-4'" @click="$router.push({name: 'Contact'})">
                Schedule 15 minute Consult
            </v-btn>
            <v-divider class="mt-3" v-if="!$vuetify.breakpoint.mdAndUp"></v-divider>
        </v-col>
        <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical></v-divider>
        
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12" :class="$vuetify.breakpoint.mdAndUp ? 'ml-8' : ''">
          <h2 class="white--text">
            What our Clients Say
          </h2>
            <testimonial/>
        </v-col>
      </v-row>
    </v-container>
    
  </v-alert>
</template>
<script>
  export default {
    components: {
      Testimonial: () => import('@/components/Testimonial'),
    }
  }
</script>
<style>
.med-text {
    font-size: 2.5em;
}
.medtwo-text {
    font-size: 1.5em;
}
</style>
